export const environment = {
	production: true,
	devMode: false,
	clearingEnabled: true,
	domain: 'https://universitycompare.com',
	port: 'http://localhost:8000/assets/json/',
	php_url: 'https://universitycompare.com/php/Web/',
	php_root: 'https://universitycompare.com/php/',
	graphql_url: 'https://universitycompare.com/api/graphql',
	api_url: 'https://universitycompare.com/api/',
	recaptcha_public_key: '6LfQ3AMoAAAAAAPEdQqdUrZPitCGPj1pYg_IzOdb',
};
